
  export default {
    name: 'SearchInput',
    props: {
      value: String
    },
    methods: {
      onFocus () {
        const inputValue = this.$refs.searchInput.value
        this.$emit('focus', inputValue)
      },
      blur () {
        this.$refs.searchInput.blur()
      }
    }
  }
