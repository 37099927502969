
  export default {
    name: 'Burger',
    components: {},
    props: {},
    data () {
      return {}
    },
    methods: {}
  }
