
  import ButtonRemove from '@/components/general/elements/ButtonRemove'
  import NumberFormat from '@/components/general/elements/NumberFormat'

  import CartItem from '@/database/models/CartItem'
  import Foil from '@/components/general/elements/Foil'
  import Row from '@/components/layout/Row'
  import FoilLayer from '@/components/general/elements/Product/FoilLayer'
  import SellCartItem from '@/database/models/SellCartItem'

  export default {
    name: 'CartReviewItem',
    components: { FoilLayer, Row, Foil, NumberFormat, ButtonRemove },
    inject: ['getLoc', 'getLocCurrency'],
    props: {
      item: {
        type: CartItem,
        required: true
      }
    },
    data () {
      return {}
    },
    computed: {
      isInCheckout (vm) {
        const routeName = vm.$route.name

        return routeName && routeName.includes('checkout')
      },
      itemPriceHasChanged(vm) {
        return vm.item.countHasChanged || vm.item.priceHasChanged
      },
      unitPrice (vm) {
        return vm.getLocCurrency(vm.item.effectiveUnitPriceLoc)
      },
      originalUnitPrice (vm) {
        return vm.getLocCurrency(vm.item.originalEffectiveUnitPriceLoc)
      },
      hasDescriptionItems (vm) {
        return (vm.descriptionItems || '').length > 0
      },
      descriptionItems (vm) {
        const items = []

        if (vm.item.isNonSingle) {
          if (vm.item.isYgoFirstEd) {
            items.push('1st edition')
          }
          if(vm.item.isDamagedPackaging) {
            items.push(vm.$t('product_packaging-damaged'))
          }
          if(vm.item.isUnpackedPackaging) {
            items.push(vm.$t('product_packaging-unpacked'))
          }
        } else {
          const expansionName = vm.getLoc(vm.item.expansionFullNameLoc)
          let condition = vm.item.condition

          if (vm.item instanceof SellCartItem && vm.item.article) {
            condition = vm.item.article.condition
          }

          if (expansionName) items.push(expansionName)
          if (vm.item.isFoil) items.push('Foil')
          if (vm.item.languageCode) items.push(vm.$t(`filter_option_lang_${vm.item.languageCode.toLowerCase()}`))
          if (condition) items.push(condition)
        }

        return items.join(', ')
      }
    },
    methods: {
      removeItemFromCart () {
        this.$emit('deleteRequest', this.item.id)
      }
    }
  }
