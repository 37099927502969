
  import LowerNavbarDrawerMixin from '~/configurations/mixins/LowerNavbarDrawerMixin'
  import NavigationPage from '~/database/models/NavigationPage'

  const CategorySublinks = () => import('@/components/layout/navbarComponent/Drawers/SubViews/CategorySublinks')
  const ImageNameBox = () => import('@/components/general/elements/ImageNameBox')

  export default {
    name: 'CardGamesDrawer',
    components: { CategorySublinks, ImageNameBox },
    mixins: [LowerNavbarDrawerMixin],
    inject: ['getLoc'],
    props: {},
    data () {
      return {
        fragment: 'card_games',
        sideMenu: null,
        rows: [],
      }
    },
    created () {
      this.rows = this.getRows(this.menuItem.children, [6, 6, 6])
      this.sideMenu = new NavigationPage(this.menuItem.children.find(item => item.url_fragment === 'card-game-accessories') || {})
      this.sideMenu.children = this.sideMenu.children.map(item => new NavigationPage(item))
    },
    methods: {
      selectItem () {
        this.$emit('itemSelect')
      },
    }
  }
