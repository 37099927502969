
  import find from 'lodash/find'
  import isEmpty from 'lodash/isEmpty'
  import Button from '~/components/general/elements/Button'
  import Foil from '~/components/general/elements/Foil'
  import NumberFormat from '~/components/general/elements/NumberFormat'
  import FoilLayer from '~/components/general/elements/Product/FoilLayer'
  import Row from '~/components/layout/Row'
  import ShoppingList from '~/database/models/ShoppingList'

  export default {
    name: 'CartToShoppingListAlert',
    components: { Foil, NumberFormat, FoilLayer, Button, Row },
    inject: ['getLoc', 'getLocCurrency'],
    props: {
      list: ShoppingList
    },
    data () {
      return {}
    },
    computed: {
      hasArticle(vm) {
        return vm.mainArticle !== null
      },

      mainListItem(vm) {
        return vm.list.parsedItem
      },

      /**
       * @returns {Article|null}
       */
      mainArticle(vm) {
        const listItem = vm.mainListItem
        let article = null

        if (listItem) {
          const parsedArticles = listItem.parsedArticles
          const articleByCondition = find(parsedArticles, article => article.condition === listItem.conditionMin)

          if(articleByCondition) {
            article = articleByCondition
          } else if (!isEmpty(parsedArticles)) {
            article = listItem.parsedArticles[0]
          }

          if(article) {
            article.product = listItem.productObject
          }
        }

        return article
      }
    },
    methods: {}
  }
