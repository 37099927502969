
  import NumberFormat from '@/components/general/elements/NumberFormat'
  import ListLoadIndicator from '@/components/general/elements/ListLoadIndicator'

  export default {
    name: 'CartShortcut',
    components: { ListLoadIndicator, NumberFormat },
    props: {
      icon: {
        type: String
      },
      itemsCount: {
        type: Number
      },
      total: {
        type: Number
      },
      buyUp: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean
      },
      loading: Boolean,
      alert: Boolean
    },
    data () {
      return {
        dataChanged: false
      }
    },
    watch: {
      total (newValue, oldValue) {
        if (newValue !== oldValue) {
          const oThis = this
          this.dataChanged = true
          setTimeout(function () {
            oThis.dataChanged = false
          }, 300)
        }
      }
    },
    methods: {}
  }
