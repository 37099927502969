
  import find from 'lodash/find'
  import isNil from 'lodash/isNil'

  export default {
    name: 'Dropdown',
    props: {
      value: {
        required: true
      },
      options: {
        type: Array,
        default: () => []
      },
      type: {
        type: String,
        default: 'quick',
        validator: val => {
          return ['full', 'quick'].includes(val)
        }
      },
      theme: {
        type: String,
        default: 'dark'
      },
      clickable: Boolean,
      hasLastItemSlot: Boolean
    },
    data () {
      return {
        isDropdownActive: false
      }
    },
    computed: {
      listeners(vm) {
        const listeners = {}

        if(vm.clickable) {
          listeners.click = function () {
            vm.isDropdownActive = !vm.isDropdownActive
          }
        } else {
          listeners.mouseover = function () {
            vm.isDropdownActive = true
          }
          listeners.mouseleave = function () {
            vm.isDropdownActive = false
          }
        }

        return listeners
      },
      hasOptions (vm) {
        return vm.options.length > 0
      },
      darkTheme (vm) {
        return vm.theme === 'dark'
      },
      fullType (vm) {
        return vm.type === 'full'
      },
      currentObject: (vm) => {
        try {
          return find(vm.options, ['value', vm.value])
        } catch (e) {
          return null
        }
      },
      currentLabel (vm) {
        if (isNil(vm.currentObject)) {
          return ''
        } else {
          return this.$t(vm.currentObject.label)
        }
      }
    },
    methods: {
      showDropdown() {
        this.isDropdownActive = true
      },
      hideDropdown() {
        this.isDropdownActive = false
      },
      clickAction (value) {
        this.$emit('input', value)
        this.hideDropdown()
      }
    }
  }
