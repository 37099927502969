class FetchManager {
  /**
   *
   * @param {Object} data
   * @param {Object=} data.filters
   */
  constructor (data = {}) {
    this.isFetching = data.isFetching || false
    this.ignoreCache = data.ignoreCache || false
    this.itemsPerPage = data.itemsPerPage || 12
    this.page = 1
    this.cancelSource = null
    this.filters = data.filters || {}
  }

  get cancelToken () {
    return this.cancelSource.token
  }

  get fetchQuery () {
    const oThis = this
    return {
      query: {
        ...oThis.filters,
        offset: oThis.itemsPerPage * (oThis.page - 1),
        limit: oThis.itemsPerPage
      },
      cancelToken: null,
      ignoreCache: oThis.ignoreCache
    }
  }

  pageUp () {
    this.page++
  }

  clear () {
    if (this.isFetching) {
      this.cancelSource = null
    }
  }

  startFetching () {
    this.isFetching = true
  }

  stopFetching () {
    this.isFetching = false
  }

  clearPaging () {
    this.page = 1
  }

  nextPage () {
    this.page++
  }

  update (props) {
    Object.assign(this, props)
  }
}

export default FetchManager
