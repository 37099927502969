
  import { mapGetters } from 'vuex'
  import UpperNavbar from '~/components/layout/navbarComponent/UpperNavbar'
  import MajorNavbar from '@/components/layout/navbarComponent/MajorNavbar'
  import LowerNavbar from '@/components/layout/navbarComponent/LowerNavbar'
  import StatusMessage from '~/components/layout/StatusMessage'

  export default {
    name: 'Navbar',
    components: {
      StatusMessage,
      UpperNavbar,
      MajorNavbar,
      LowerNavbar
    },
    inject: ['getLoc'],
    data () {
      return {
        shouldShowShadow: false,
        showNavbarInWhole: true,
        lastScrollPosition: 0
      }
    },
    head () {
      return {
        bodyAttrs: {
          class: this.hasStatusMessage ? 'status-message-in' : ''
        }
      }
    },
    computed: {
      ...mapGetters({
        hasStatusMessage: 'general/hasStatusMessage',
        statusMessage: 'general/statusMessage'
      })
    },
    mounted () {
      window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
      toggleShadow (value) {
        this.shouldShowShadow = value
      },

      onScroll () {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        if (currentScrollPosition < 0) {
          return
        }
        // Stop executing this function if the difference between
        // current scroll position and last scroll position is less than some offset
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
          return
        }
        this.showNavbarInWhole = currentScrollPosition < this.lastScrollPosition
        this.lastScrollPosition = currentScrollPosition
      }
    }
  }
