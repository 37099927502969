
  import Star from '@/components/general/elements/Star'
  import Row from '@/components/layout/Row'

  export default {
    name: 'Foil',
    functional: true,
    components: { Row, Star },
    props: {
      hiddenLabel: Boolean
    },
    methods: {}
  }
