import { SearchSection } from '@/services/eventBus/EventBus'

class SearchResultItem {
  constructor (props) {
    this.title = props.localized_name || props.name || ''
    this.link = props.link || { to: 'search' }
    this.icon = null
    this.game = props.game

    if ('icon_url' in props) {
      this.icon = props.icon_url
    }

    if ('product' in props) {
      if ('localized_name' in props.product) {
        this.title = props.product.localized_name
      }
    }
  }

  static ItemFilterMap = new Map([
    ['expansions', 'expansion'],
    ['categories', 'product_category'],
    ['nonsingle_products', 'fulltext'],
    ['singles_mtg', 'fulltext'],
    ['singles_lorcana', 'fulltext'],
    ['buylist_offers', 'buylist'],
    ['tags', 'product_tags'],
    ['games', 'product__expansion__game']
  ])

  static SectionRestriction = new Map([
    ['categories', SearchSection.products],
    ['nonsingle_products', SearchSection.products],
    ['singles_mtg', SearchSection.looseCards],
    ['singles_lorcana', SearchSection.lorcanaSingles],
    ['tags', SearchSection.products],
    ['games', SearchSection.products]
  ])

  static SectionPreference = new Map([
    ['expansions', SearchSection.products],
    ['categories', SearchSection.products],
    ['nonsingle_products', SearchSection.products],
    ['singles_mtg', SearchSection.looseCards],
    ['singles_lorcana', SearchSection.lorcanaSingles],
    ['tags', SearchSection.products],
    ['games', SearchSection.products]
  ])

  // For values from search dropdown
  static SectionForCategory = new Map([
    ['card_games', 22],
    ['tabletop_games', 23],
    ['miniatures', 24],
    ['comics', 2601]
  ])

  static mtgGame = '40e972fe-c136-4d66-b61a-89f6be0f93d5'
}

export default SearchResultItem
