
  import Row from '~/components/layout/Row'
  import EventBus, { BusEvents } from '~/services/eventBus/EventBus'

  export default {
    name: 'StatusMessage',
    components: { Row },
    props: {
      content: String,
      type: {
        type: String,
        default: 'success',
        validator: val => {
          return ['success', 'info', 'warning', 'error'].includes(val)
        }
      }
    },
    data () {
      return {
        isClickable: false,
      }
    },
    computed: {
      icon(vm) {
        let icon = 'warning'

        if(vm.type === 'info') {
          icon = 'info'
        } else if (vm.type === 'success') {
          icon = 'check-rounded'
        }

        return icon
      }
    },
    watch: {
      content: {
        immediate: true,
        async handler() {
          await this.$nextTick()
          this.checkClickAvailability()
        }
      }
    },
    mounted () {
      EventBus.$on(BusEvents.app.windowResize, this.windowSizeChanged)
    },
    beforeDestroy () {
      EventBus.$off(BusEvents.app.windowResize, this.windowSizeChanged)
    },
    methods: {
      checkClickAvailability() {
        if ('status' in this.$refs && this.$refs.status) {
          // If line clamp is applied, enable tooltip with full name
          this.isClickable = this.$refs.status.scrollHeight > this.$refs.status.clientHeight
        }
      },
      windowSizeChanged() {
        this.checkClickAvailability()
      },

      onClick() {
        if(this.isClickable) {
          this.$modal.showMessageAlert({ message: this.content })
        }
      }
    }
  }
