import { render, staticRenderFns } from "./LowerNavbarItem.vue?vue&type=template&id=d5cf15be&functional=true"
import script from "./LowerNavbarItem.vue?vue&type=script&lang=js"
export * from "./LowerNavbarItem.vue?vue&type=script&lang=js"
import style0 from "./LowerNavbarItem.vue?vue&type=style&index=0&id=d5cf15be&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports