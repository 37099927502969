
  import Row from '@/components/layout/Row'
  import LowerNavbarDrawerMixin from '~/configurations/mixins/LowerNavbarDrawerMixin'
  import NavigationPage from '~/database/models/NavigationPage'

  const ImageNameBox = () => import('@/components/general/elements/ImageNameBox')

  export default {
    name: 'MiniaturesDrawer',
    components: { Row, ImageNameBox },
    mixins: [LowerNavbarDrawerMixin],
    inject: ['getLoc'],
    props: {},
    data () {
      return {
        fragment: 'miniatures',
        rows: [],
      }
    },
    created () {
      this.rows = this.getRows(this.menuItem.children.map(item => new NavigationPage(item)), [3, 6, 6, 6])
    },
    methods: {
      selectItem () {
        this.$emit('itemSelect')
      },
    }
  }
