
  export default {
    name: 'ShadowBox',
    functional: true,
    components: {},
    props: {
      title: {
        type: String
      },
      icon: {
        type: String
      },
      background: {
        type: String,
        default: '/img/background_kusovky.png'
      }
    }
  }
