
  import debounce from 'lodash/debounce'
  import isEmpty from 'lodash/isEmpty'
  import { mapGetters } from 'vuex'
  import FetchManager from '@/structures/FetchManager'
  import { runTask } from '~/services/network/utils/axios'
  import FilterConfig from '~/structures/filters/FilterConfig'
  import SearchResult from '@/components/search/SearchResult'
  import Dropdown from '@/components/general/Dropdown'
  import SearchInput from '@/components/search/SearchInput'

  import SearchResultItem from '@/components/search/SearchResultItem'


  import { EventBus, SearchSection } from '@/services/eventBus/EventBus'
  import NLink from '~/components/utils/NLink'
  import { localizeRouteName } from '~/services/RouterService'
  import { getSearchPreviewItems } from '~/workers/network.worker'

  const SearchResultContainer = () => import('@/components/search/SearchResultContainer')

  export default {
    name: 'SearchModule',
    components: { NLink, Dropdown, SearchInput, SearchResultContainer },
    props: {},
    data () {
      return {
        searchResult: new SearchResult({}),
        fetchManager: new FetchManager({}),
        showSearchResults: false,
        searchSection: FilterConfig.fields.searchModule.options[0].value,
        dropdownConfig: FilterConfig.fields.searchModule,
        queryString: '',
        actionMap: new Map([
          [
            'all',
            {
              name: 'search',
              query: {}
            }
          ],
          [
            'mtg_buylist',
            {
              name: 'singles_guide_mtg_buylist',
              query: {}
            }
          ]
        ])
      }
    },
    computed: {
      ...mapGetters({
        language: 'general/getLanguage'
      }),
      hasValidInput (vm) {
        return !isEmpty(vm.queryString)
      }
    },
    methods: {
      callFetch: debounce(
        async function () {
          const oThis = this

          await this.$nextTick()
          await this.fetchManager.clear()

          setTimeout(function () {
            oThis.fetchResults()
          }, 1)
        },
        250,
        { leading: false, trailing: true }
      ),
      async fetchResults () {
        if (this.fetchManager.isFetching || !this.hasValidInput) {
          return
        }

        this.fetchManager.startFetching()
        await this.$nextTick()

        try {
          const result = await runTask(getSearchPreviewItems({
            cancelId: 'search-preview-list',
            query: {
              q: this.queryString.trim(),
              t: this.searchSection
            }}
          ))
          this.searchResult.setData(result, this.searchSection, this.queryString, {
            'singles_lorcana': this.$store.state.configurations.configuration_global.lorcana_id,
            'singles_mtg': this.$store.state.configurations.configuration_global.mtg_id,
          })
          await this.$nextTick()
        } catch (e) {
          this.$log.error(e)
        } finally {
          this.fetchManager.stopFetching()
        }
      },
      hideSearchResults () {
        this.showSearchResults = false
      },

      clearInput () {
        this.queryString = ''
        this.$refs.searchInput.blur()
      },

      onItemSelected () {
        this.clearInput()
      },
      onInputChange () {
        this.showSearchResults = this.queryString.length > 0
        this.searchResult = new SearchResult()

        this.callFetch()
      },
      onInputFocus (inputValue) {
        this.fetchResults()
        this.showSearchResults = inputValue.length > 0
      },
      submitInputAction () {
        if (!this.hasValidInput) return

        const baseSectionValue = FilterConfig.fields.searchModule.options[0].value
        /** @type {Object} */
        let routerConfig = this.actionMap.get(baseSectionValue)

        if (this.searchSection !== baseSectionValue && this.actionMap.has(this.searchSection)) {
          routerConfig = this.actionMap.get(this.searchSection)
        }

        EventBus.search.shouldRemapSummary = true
        EventBus.resetSectionSetting()
        EventBus.resetSummaryActiveItems()
        EventBus.resetSearchSinglesFilter(this.globalCurrencyShortcutLower)
        EventBus.resetSearchSinglesLorcanaFilter(this.globalCurrencyShortcutLower)
        EventBus.resetSearchNonSinglesFilter(this.globalCurrencyShortcutLower)
        EventBus.resetBuylistSinglesFilter(this.globalCurrencyShortcutLower)

        routerConfig.query.fulltext = this.queryString

        if (SearchResultItem.SectionForCategory.has(this.searchSection)) {
          routerConfig.query.product_category = SearchResultItem.SectionForCategory.get(this.searchSection)
          routerConfig.query.restricted_to = SearchSection.products
        }

        // routerConfig.query.t = Date.now()
        routerConfig = {...routerConfig}
        routerConfig.name = localizeRouteName(routerConfig.name, this.language)

        this.$router.push(routerConfig)

        this.clearInput()
      }
    },
    watch: {
      searchSection () {
        this.onInputChange()
      },
      queryString: {
        handler () {
          this.onInputChange()
        }
      },
      $route () {
        this.hideSearchResults()
      }
    }
  }
