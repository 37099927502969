
  import ImageNameBox from '~/components/general/elements/ImageNameBox'
  import Row from '~/components/layout/Row'
  import LowerNavbarDrawerMixin from '~/configurations/mixins/LowerNavbarDrawerMixin'
  import NavigationPage from '~/database/models/NavigationPage'

  export default {
    name: 'PokemonDrawer',
    components: { Row, ImageNameBox },
    mixins: [LowerNavbarDrawerMixin],
    inject: ['getLoc'],
    props: {},
    data () {
      return {
        fragment: 'pokemon',
        rows: []
      }
    },

    created () {
      this.rows = this.getRows(this.menuItem.children.map(item => new NavigationPage(item)), [6, 6])
    },
    methods: {
      selectItem () {
        this.$emit('itemSelect')
      }
    }
  }
