
  import isString from 'lodash/isString'
  import { mapActions, mapGetters } from 'vuex'

  import ProgressBar from '@/components/general/elements/ProgressBar'
  import CartShortcut from '@/components/general/elements/Cart/CartShortcut'
  import CartItemsReview from '@/components/layout/navbarComponent/CartItemsReview'
  import Row from '@/components/layout/Row'
  import ActionLink from '@/components/general/elements/ActionLink'
  import CartToShoppingListAlert from '~/components/layout/navbarComponent/CartToShoppingListAlert'
  import NLink from '~/components/utils/NLink'
  import { localizeRouteName } from '~/services/RouterService'

  export default {
    name: 'UserStateReview',
    components: { CartToShoppingListAlert, NLink, ActionLink, Row, CartItemsReview, CartShortcut, ProgressBar },
    props: {},
    data () {
      return {
        shouldShowCartReview: false,
        cartReviewSection: 'buy'
      }
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'authentication/isAuthenticated',
        userPicture: 'authentication/userPicture',
        username: 'authentication/userFullName',
        userCredit: 'authentication/userTotalCredit',
        userProgress: 'authentication/userLoyaltyProgressToNextLevel',
        profileRank: 'authentication/userLoyaltyLevel',
        language: 'general/getLanguage',

        buyUpCartPriceHasChanged: 'cartBuyup/hasPriceChange',
        buyUpCartItems: 'cartBuyup/cartItems',
        buyUpItemsCount: 'cartBuyup/buyupItemsCount',
        buyUpItemsTotal: 'cartBuyup/buyupItemsTotal',
        buyUpCartIsOperating: 'cartBuyup/isOperating',

        buyCartItems: 'cart/cartItems',
        buyItemsCount: 'cart/buyItemsCount',
        buyItemsTotal: 'cart/buyItemsTotal',
        buyItemsUserTotal: 'cart/buyItemsUserTotal',
        buyItemsTotalCur: 'cart/buyItemsTotalWithCurrency',
        buyCartIsOperating: 'cart/isOperating',

        userPriceDisplay: 'cart/userPriceDisplay',

        hasCartItems: 'cart/hasCartItems',
        hasBuyupCartItems: 'cartBuyup/hasCartItems',

        hasLists: 'shoppingLists/hasLists',
        isCartAlertRequested: 'shoppingLists/isCartAlertRequested',
        lastShoppingList: 'shoppingLists/lastShoppingList'
      }),

      showCartAlert(vm){
        return vm.isCartAlertRequested && !vm.hasCartItems
      },

      showCartItemsReview(vm) {
        return vm.shouldShowCartReview && (vm.cartReviewSection !== 'buy' || (vm.cartReviewSection === 'buy' && !vm.showCartAlert))
      },
      hasUserPicture(vm) {
        return isString(vm.userPicture)
      },
      cartItems (vm) {
        return vm[`${vm.cartReviewSection}CartItems`]
      },
      itemsCount (vm) {
        return vm[`${vm.cartReviewSection}ItemsCount`]
      },
      itemsTotal (vm) {
        if (vm.cartReviewSection === 'buy') {
          return vm.userPriceDisplay
        }

        return vm[`${vm.cartReviewSection}ItemsTotal`]
      }
    },
    methods: {
      ...mapActions({
        logout: 'authentication/logout'
      }),

      showCartReview (section) {
        this.shouldShowCartReview = true
        this.cartReviewSection = section
      },
      hideCartReview () {
        this.shouldShowCartReview = false
      },
      showCheckoutDetail () {
        const cartType = this.cartReviewSection === 'buy' ? '' : 'Buyup'
        this.$router.push({ name: localizeRouteName(`checkout${cartType}Step1`, this.language) })
      },
      deleteCartItem (cartItemId) {
        const cartType = this.cartReviewSection === 'buy' ? 'cart' : 'cartBuyup'
        this.$store.dispatch(`${cartType}/removeItemFromCart`, cartItemId)
      },

      logoutAction () {
        if (this.hasCartItems || this.hasBuyupCartItems) {
          let message = 'action_logout-cart-warning'

          if (this.hasBuyupCartItems) {
            message = 'action_logout-buycart-warning'
          }

          if (this.hasCartItems && this.hasBuyupCartItems) {
            message = 'action_logout-carts-warning'
          }

          this.showCartAlertDialog(this.$t(message))
        } else {
          this.logout()
        }
      },

      showCartAlertDialog (message) {
        const oThis = this
        this.$modal.showAlertDialog({
          message,
          onComplete () {
            oThis.logout()
            oThis.$modal.hide()
          }
        })
      }
    }
  }
