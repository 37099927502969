
  import LowerNavbarItem from '@/components/layout/navbarComponent/LowerNavbarItem'
  import MTGDrawer from '@/components/layout/navbarComponent/Drawers/MTGDrawer'
  import MiniaturesDrawer from '@/components/layout/navbarComponent/Drawers/MiniaturesDrawer'
  import PokemonDrawer from '~/components/layout/navbarComponent/Drawers/PokemonDrawer'
  import CardGamesDrawer from '@/components/layout/navbarComponent/Drawers/CardGamesDrawer'
  import TabletopGamesDrawer from '@/components/layout/navbarComponent/Drawers/TabletopGamesDrawer'
  import SinglesDrawer from '@/components/layout/navbarComponent/Drawers/SinglesDrawer'
  import NavigationMap from '~/structures/NavigationMap'
  import Localizable from '~/configurations/mixins/Localizable'
  import DoNotMissDrawer from '~/components/layout/navbarComponent/Drawers/DoNotMissDrawer.vue'
  import NavigationPage from '~/database/models/NavigationPage'

  const LowerNavbarDrawer = () => import('@/components/layout/navbarComponent/LowerNavbarDrawer')

  export default {
    name: 'LowerNavbar',
    components: {
      LowerNavbarDrawer,
      LowerNavbarItem
    },
    mixins: [Localizable],
    provide () {
      return {
        getLoc: this.getLoc
      }
    },
    props: {},
    data () {
      return {
        shouldShowDrawer: false,
        disabledCategories: [],

        staticItems: {
          buylist: NavigationMap.buylist,
          blog: NavigationMap.blog,
          gamingClub: NavigationMap.gamingClub
        },
        icons: {
          'game-club': 'PLAYHOUSE',
          'dont-miss': 'DONT-MISS',
          'single-cards': 'SINGLES',
        },
        currentCategoryId: 'null',
        componentsDictionary: {
          null: null,
          mtg: MTGDrawer,
          pokemon: PokemonDrawer,
          miniatures: MiniaturesDrawer,
          card_games: CardGamesDrawer,
          tabletop_games: TabletopGamesDrawer,
          'dont-miss': DoNotMissDrawer,
          'single-cards': SinglesDrawer,
        }
      }
    },
    computed: {
      drawerComponent (vm) {
        return vm.componentsDictionary[vm.currentCategoryId]
      },
      menuItems(vm) {
        return vm.$store.state.menu.menu.filter(item => item.special_positioning === 'NAV_MENU_TOP').map(item => new NavigationPage(item))
      },
    },
    methods: {
      showDrawer (categoryKey) {
        if(!(categoryKey in this.componentsDictionary)) {
          return this.hideDrawer()
        }
        this.shouldShowDrawer = true
        this.currentCategoryId = categoryKey
        this.$emit('shadowRequest', true)
      },
      hideDrawer () {
        this.shouldShowDrawer = false
        this.$emit('shadowRequest', false)
      }
    }
  }
