import isArray from 'lodash/isArray'
import map from 'lodash/map'

import { SearchSection } from '@/services/eventBus/EventBus'
import SearchResultItem from '@/components/search/SearchResultItem'
import { titleSKU } from '~/services/NavigationService'

class SearchResultSection {
  constructor (props) {
    this.title = props.title || ''
    this.urlId = props.urlId || ''
    this.count = props.count || 0
    this.items = map(props.items || [], (item) => {
      return new SearchResultItem(item)
    })

    this.route = {
      name: 'search'
    }
  }

  get link () {
    return `/${this.urlId}`
  }

  /**
   *
   * @param {Array} items
   * @param {String} searchSection
   * @param {String} term
   */
  setItems (items, searchSection, term) {
    const sectionRestriction = SearchResultItem.SectionRestriction.get(this.urlId)
    const sectionPreference = SearchResultItem.SectionPreference.get(this.urlId)
    const configKey = SearchResultItem.ItemFilterMap.get(this.urlId)

    this.count = items.length
    this.items = map(items || [], (item) => {
      let isProductDetail = false
      let queryObject = {
        fulltext: ''
      }

      const linkConfig = {
        name: 'search',
        params: {}
      }

      const resultItem = new SearchResultItem({
        ...item
      })

      // BUYLIST SEARCH
      if (searchSection === 'mtg_buylist') {
        linkConfig.name = 'singles_guide_mtg_buylist'
        delete queryObject.section

        if (configKey === 'buylist') {
          // If looking for card item by its name from buylist
          queryObject.fulltext = resultItem.title || ''
        } else {
          // If anything else like expansion etc.
          queryObject[configKey] = [item.id]
        }
      } else {
        if (sectionPreference) {
          queryObject.section = sectionPreference
        }

        // USUAL SEARCH
        if (configKey === 'fulltext') {
          // If looking for card item by its name
          queryObject.fulltext = resultItem.title || ''

          // If NONSINGLE PRODUCT found, redirect to detail of that product
          if ('article_sku' in item && isArray(item.article_sku) && item.article_sku.length > 0) {
            linkConfig.name = 'productDetail'
            linkConfig.params.titleSKU = titleSKU(resultItem.title, item.article_sku[0])
            isProductDetail = true
            queryObject = {}
          } else {
            // If SINGLE CARD product
            queryObject.label = this.urlId.includes('nonsingle') ? 'products' : 'singles'
            queryObject.is_exact = true
          }
        } else if (configKey === 'expansion') {
          // If expansion
          if ('game' in item) {
            if (item.game === SearchResultItem.mtgGame) {
              queryObject.section = SearchSection.looseCards
            } else {
              queryObject.section = SearchSection.products
              queryObject.restricted_to = SearchSection.products
            }
          }

          queryObject.expansion = [item.id]
          queryObject.product_expansion = [item.id]
        } else {
          // If anything else
          queryObject[configKey] = [item.id]
        }

        if(!isProductDetail) {
          queryObject.term = resultItem.title
        }

        if (sectionRestriction && !isProductDetail) {
          queryObject.restricted_to = sectionRestriction
        }
      }

      resultItem.link = {
        ...linkConfig,
        query: {
          ...queryObject
        }
      }

      return resultItem
    })

    // ROUTE for all items
    if (searchSection === 'mtg_buylist') {
      // BUYLIST

      const query = {}

      if (configKey === 'buylist') {
        // If looking for card item by its name from buylist
        query.fulltext = term
      } else {
        // If anything else like expansion etc.
        query[configKey] = map(items || [], item => item.id)
        query.label = configKey
        query.term = term
      }

      this.route = {
        name: 'singles_guide_mtg_buylist',
        query
      }
    } else {
      // SELL LIST

      const query = {
        section: SearchSection.products,
        term
      }

      if (sectionRestriction) {
        query.restricted_to = sectionRestriction
      }
      if (sectionPreference) {
        query.section = sectionPreference
      }

      if (configKey !== 'fulltext') {
        query[configKey] = map(items || [], item => item.id)
        query.label = configKey

        if (configKey === 'expansion') {
          query.product_expansion = query.expansion
        }
      } else {
        query.fulltext = term
        query.section = this.urlId.includes('nonsingle') ? SearchSection.products : this.urlId.includes('lorcana') ? SearchSection.lorcanaSingles : SearchSection.looseCards
        query.label = this.urlId.includes('nonsingle') ? 'products' : 'singles'
      }

      this.route = {
        name: 'search',
        query
      }
    }
  }
}

export default SearchResultSection
