
  import { mapGetters } from 'vuex'

  import SearchModule from '@/components/search/SearchModule'
  import UserStateReview from '@/components/layout/navbarComponent/UserStateReview'
  import CartShortcut from '@/components/general/elements/Cart/CartShortcut'
  import Burger from '@/components/layout/navbarComponent/Burger'
  import Row from '@/components/layout/Row'
  import NLink from '~/components/utils/NLink'

  export default {
    name: 'MajorNavbar',
    components: { NLink, Row, Burger, CartShortcut, SearchModule, UserStateReview },
    computed: {
      ...mapGetters({

        buyUpItemsCount: 'cartBuyup/buyupItemsCount',
        buyUpItemsTotal: 'cartBuyup/buyupItemsTotal',

        buyItemsCount: 'cart/buyItemsCount',
        buyItemsTotal: 'cart/userPriceDisplay',

        buyUpCartIsOperating: 'cartBuyup/isOperating',
        buyCartIsOperating: 'cart/isOperating',

        hasCartItems: 'cart/hasCartItems',
        isCartAlertRequested: 'shoppingLists/isCartAlertRequested',
        lastShoppingList: 'shoppingLists/lastShoppingList'
      }),

      showCartAlert(vm){
        return vm.isCartAlertRequested && !vm.hasCartItems
      },

      checkoutLink(vm) {
        let value = {name: 'checkoutStep1'}

        if(vm.showCartAlert) {
          value = {name: 'checkoutStep1', query: {shopping_list: vm.lastShoppingList.id}}
        }

        return value
      }
    }
  }
