
import Row from '~/components/layout/Row.vue'
import LowerNavbarDrawerMixin from '~/configurations/mixins/LowerNavbarDrawerMixin'
import ShadowBox from '~/components/general/elements/ShadowBox.vue'
import ItemBubble from '~/components/general/elements/Product/ItemBubble.vue'
import NavigationPage from '~/database/models/NavigationPage'

export default {
  name: 'DoNotMissDrawer',
  components: {ItemBubble, ShadowBox, Row },
  mixins: [LowerNavbarDrawerMixin],
  inject: ['getLoc'],
  data() {
    return {
      fragment: 'dont-miss',
      rows: [],
    }
  },
  created() {
    this.rows = this.getRows(this.menuItem.children.map(item => new NavigationPage(item)), [3])
  },
  methods: {
    getBubbleType(id) {
      let type = 'new'

      if(['sale', 'discounted'].includes(id)) type = 'sale-promo'
      if(id === 'presale') type = 'presale'

      return type
    },
    getBubbleContent(id) {
      let type = 'NEW'

      if(['sale', 'discounted'].includes(id)) type = '%'
      if(id === 'presale') type = ''

      return type
    },
  },
}
