import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import SearchResultSection from './SearchResultSection'

class SearchResult {
  constructor () {
    this.sections = {
      expansions: new SearchResultSection({
        title: 'title_expansions',
        urlId: 'expansions',
        count: 0,
        items: []
      }),
      categories: new SearchResultSection({
        title: 'title_categories',
        urlId: 'categories',
        count: 0,
        items: []
      }),
      nonsingle_products: new SearchResultSection({
        title: 'title_products',
        urlId: 'nonsingle_products',
        count: 0,
        items: []
      }),
      singles_mtg: new SearchResultSection({
        title: 'search_title_singles_mtg',
        urlId: 'singles_mtg',
        count: 0,
        items: []
      }),
      singles_lorcana: new SearchResultSection({
        title: 'search_title_singles_lorcana',
        urlId: 'singles_lorcana',
        count: 0,
        items: []
      }),
      buylist_offers: new SearchResultSection({
        title: 'title_buylist-offers',
        urlId: 'buylist_offers',
        count: 0,
        items: []
      }),
      tags: new SearchResultSection({
        title: 'title_tags',
        urlId: 'tags',
        count: 0,
        items: []
      }),
      games: new SearchResultSection({
        title: 'title_games',
        urlId: 'games',
        count: 0,
        items: []
      })
    }
    this.throttledSections = cloneDeep(this.sections)
  }

  get isEmpty () {
    for (const key in this.sections) {
      if (key in this.sections) {
        if (this.sections[key].items.length > 0) return false
      }
    }

    return true
  }

  get totalCount () {
    let sum = 0

    for (const key in this.sections) {
      if (key in this.sections) {
        sum += this.sections[key].count
      }
    }

    return sum
  }

  get countSectionPair () {
    return {
      category_count: 'categories',
      tag_count: 'tags',
      expansion_count: 'expansions',
      game_count: 'games',
      nonsingle_product_count: 'nonsingle_products',
      mtg_single_count: 'singles_mtg',
      single_count: 'singles_lorcana',
      buylist_offer_count: 'buylist_offers'
    }
  }

  static piecePerSection = {
    expansions: 2,
    categories: 3,
    nonsingle_products: 3,
    singles_mtg: 4,
    singles_lorcana: 4,
    buylist_offers: 2,
    tags: 0,
    games: 3
  }

  /**
   * Set search results from JSON response
   * @param {Object} data
   * @param {String} searchSection
   * @param {String} term - Searched term in search input
   * @param {{key:game}} gamePairs - Game pairs
   */
  setData (data, searchSection, term, gamePairs) {
    if (isNull(data)) return

    for (const key in data) {
      if (key in data && key in this.sections) {
        this.sections[key].setItems(data[key], searchSection, term)
      }
    }

    this.sections.singles_lorcana.setItems(
      data.singles.filter(item => item.game === gamePairs.singles_lorcana),
      searchSection,
      term
    )
    this.sections.singles_mtg.setItems(
      data.singles.filter(item => item.game === gamePairs.singles_mtg),
      searchSection,
      term
    )

    if ('search_summary' in data) {
      for (const key in data.search_summary) {
        if (key in data.search_summary && key in this.countSectionPair) {
          this.sections[this.countSectionPair[key]].count = data.search_summary[key]
        }
      }
    }

    this.throttleSections()
  }

  /**
   * Throttles number of section results
   */
  throttleSections () {
    const oThis = this
    let emptySpaces = 0
    const itemfulSections = new Set([])
    this.throttledSections = cloneDeep(this.sections)
    const backupSections = cloneDeep(this.sections)

    for (const key in this.throttledSections) {
      if (key in this.throttledSections && key in SearchResult.piecePerSection) {
        const section = this.throttledSections[key]

        if (section.items.length < SearchResult.piecePerSection[key]) {
          emptySpaces += SearchResult.piecePerSection[key] - section.items.length
        } else {
          section.items = section.items.slice(0, SearchResult.piecePerSection[key])
          itemfulSections.add(key)
        }
      }
    }

    // Fill empty space in search summary
    if (emptySpaces > 0 && !isEmpty(itemfulSections)) {
      let possibleSections = Array.from(itemfulSections)
      let division = Math.ceil(emptySpaces / possibleSections.length)

      forEach(possibleSections, key => {
        if (key in backupSections && key in SearchResult.piecePerSection) {
          const section = backupSections[key]
          const throttledSection = oThis.throttledSections[key]
          itemfulSections.delete(key)
          possibleSections = Array.from(itemfulSections)

          if (section.items.length < (SearchResult.piecePerSection[key] + division)) {
            throttledSection.items = section.items
            emptySpaces += (SearchResult.piecePerSection[key] + division) - section.items.length
            division = Math.ceil(emptySpaces / possibleSections.length)
          } else {
            throttledSection.items = section.items.slice(0, SearchResult.piecePerSection[key] + division)
            emptySpaces -= division
            division = Math.ceil(emptySpaces / possibleSections.length)
          }
        }
      })
    }
  }
}

export default SearchResult
