
  import EventBus from '@/services/eventBus/EventBus'

  import ImageNameBox from '~/components/general/elements/ImageNameBox'
  import LowerNavbarDrawerMixin from '~/configurations/mixins/LowerNavbarDrawerMixin'
  import NavigationPage from '~/database/models/NavigationPage'

  export default {
    name: 'MTGDrawer',
    components: { ImageNameBox },
    mixins: [LowerNavbarDrawerMixin],
    inject: ['getLoc'],
    props: {},
    data () {
      return {
        fragment: 'mtg',
        rows: [],
        singles: {
          sell: null,
          buylist: null,
        },
      }
    },
    created () {
      const children = this.menuItem.children.filter(page => !['singles', 'buylist'].includes(page.url_fragment) )
      this.rows = this.getRows(children, [6, 6])
      this.singles.sell = new NavigationPage(this.menuItem.children.find(item => item.url_fragment === 'singles') || {})
      this.singles.buylist = new NavigationPage(this.menuItem.children.find(item => item.url_fragment === 'buylist') || {})
    },
    methods: {
      async selectItem () {
        this.$emit('itemSelect')

        await this.$nextTick()
        EventBus.resetSinglesFilter()
        EventBus.resetBuylistSinglesFilter()
      },
    }
  }
