
  import { mapGetters } from 'vuex'
  import Button from '@/components/general/elements/Button'
  import NumberFormat from '@/components/general/elements/NumberFormat'

  import CartReviewItem from '@/components/layout/list/items/CartReviewItem'

  export default {
    name: 'CartItemsReview',
    components: { CartReviewItem, NumberFormat, Button },
    props: {
      cartItems: {
        type: Array,
        default: () => []
      },
      itemsCount: {
        type: Number,
        default: 0
      },
      total: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {}
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'authentication/isAuthenticated'
      }),
      totalPriceLabel (vm) {
        if (vm.isAuthenticated) {
          return 'checkout_price_total_mine'
        }

        return 'checkout_price_total'
      }
    },
    methods: {
    }
  }
