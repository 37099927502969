
  export default {
    name: 'ImageNameBox',
    functional: true,
    components: {},
    props: {
      title: {
        type: String
      },
      image: {
        type: String
      }
    }
  }
