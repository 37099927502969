
  import NavigationWrapper from '@/components/general/elements/NavigationWrapper'
  import NavigationPage from '~/database/models/NavigationPage'

  export default {
    name: 'LowerNavbarItem',
    functional: true,
    components: { NavigationWrapper },
    inject: ['getLoc'],
    props: {
      page: {
        type: NavigationPage,
        default: null
      },
      title: String,
      disabled: Boolean,
      forced: Boolean,
      forcedPage: Object,
      icon: String
    }
  }
