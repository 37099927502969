
  import { mapActions, mapGetters } from 'vuex'
  import { availableShippingCountryOptions } from '@/structures/FormField'
  import Flag from '~/components/general/elements/Flag'
  import { executeGAEvent } from '~/services/AnalyticsService'

  const UpperNavbarItem = () => import('@/components/layout/navbarComponent/UpperNavbarItem')

  export default {
    name: 'UpperNavbar',
    components: { Flag, UpperNavbarItem },

    data () {
      const oThis = this

      return {
        isLoading: false,
        leftNavbarItems: {
          country: {
            action: async (newDataObject) => {
              this.leftNavbarItems.country.current = newDataObject
              await oThis.updatePreferences({ country: newDataObject })
            },
            name: 'setting_country',
            splitted: true,
            current: this.$store.state.general.settings.country,
            options: availableShippingCountryOptions(oThis.language)
          },
          language: {
            action: async (newDataObject) => {
              const current = this.leftNavbarItems.language.current
              executeGAEvent('settings', 'language change', `${current} -> ${newDataObject}`)

              this.leftNavbarItems.language.current = newDataObject
              await oThis.updatePreferences({ language: newDataObject })
            },
            name: 'setting_language',
            splitted: true,
            current: oThis.language,
            options: {
              cs: 'CS',
              en: 'EN'
            }
          },
          currency: {
            action: async (newDataObject) => {
              const current = this.leftNavbarItems.currency.current
              executeGAEvent('settings', 'currency change', `${current} -> ${newDataObject}`)

              this.leftNavbarItems.currency.current = newDataObject
              await oThis.updatePreferences({ currency: newDataObject })
            },

            name: 'setting_currency',
            splitted: false,
            disabled: this.$store.getters['authentication/isAuthenticated'],
            current: this.$store.getters['general/getCurrency'],
            options: {
              CZK: 'currency_czk',
              EUR: 'currency_eur'
            }
          }
        },
        rightNavbarItems: {

          club: oThis.$themeSettings.features.gamingClub.enabled ? {
            title: 'page_games-club',
            link: {name: 'gamingClub'},
            splitted: true
          } : {
            title: 'page_games-club',
            link: 'https://herna.najada.cz',
            isExternal: true,
            splitted: true
          },
          contact: {
            title: 'page_contact',
            link: {name: 'contactPage'},
            splitted: true
          },
          fidelityProgram: {
            title: 'page_loyalty-program',
            link: {name: 'shoppingLoyaltyPage'}
          }
        }
      }
    },

    computed: {
      ...mapGetters({
        isAuthenticated: 'authentication/isAuthenticated',
        country: 'general/getCountry',
        currency: 'general/getCurrency',
        language: 'general/getLanguage'
      })
    },
    methods: {
      ...mapActions({
        updateHello: 'general/updateHello'
      }),

      async updatePreferences (data) {
        this.isLoading = true
        await this.$nextTick()

        try {
          await this.updateHello(data)
        } catch (e) {
          this.$log.error(e)
        } finally {
          this.isLoading = false
        }
      }
    },
    watch: {
      language: {
        immediate: true,
        handler (newValue) {
          this.leftNavbarItems.language.current = newValue
          this.leftNavbarItems.country.options = availableShippingCountryOptions(newValue)
        }
      },
      country: {
        immediate: true,
        handler (newValue) {
          this.leftNavbarItems.country.current = newValue
        }
      },
      currency: {
        immediate: true,
        handler (newValue) {
          this.leftNavbarItems.currency.current = newValue
        }
      },
      isAuthenticated: {
        immediate: true,
        handler (newValue) {
          this.leftNavbarItems.currency.disabled = newValue
        }
      }
    }
  }
