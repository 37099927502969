
import ShadowBox from '~/components/general/elements/ShadowBox.vue'
import LowerNavbarDrawerMixin from '~/configurations/mixins/LowerNavbarDrawerMixin'
import NavigationPage from '~/database/models/NavigationPage'

export default {
  name: 'SinglesDrawer',
  components: { ShadowBox },
  mixins: [LowerNavbarDrawerMixin],
  inject: ['getLoc'],
  data() {
    return {
      fragment: 'single-cards',
      columns: [],
    }
  },
  created() {
    this.columns = this.menuItem.children.map((item) => new NavigationPage(item))
  },
}
