import { mapGetters } from 'vuex'
import NavigationPage from '~/database/models/NavigationPage'

export default {
  data() {
    return {
      fragment: ''
    }
  },
  computed: {
    ...mapGetters({
      getMenu: 'menu/getMenu'
    }),
    menuItem(vm) {
      return vm.getMenu(vm.fragment)
    },
  },
  methods: {
    /**
     * Retrieves rows of navigation pages based on an array of items and row templates.
     *
     * @param {Array} items - The array of items to be divided into rows.
     * @param {Array} templates - The array of row templates specifying the number of items in each row. Defaults to [5, 5].
     * @returns {Array} - The array of rows, where each row contains NavigationPage objects created from the items.
     */
    getRows(items, templates = [5, 5]) {
      const itemsCopy = [...items]
      const rows = []

      for (const rowTemplate of templates) {
        rows.push(itemsCopy.splice(0, rowTemplate).map(item => new NavigationPage(item)))
      }

      return rows
    }
  },
}
