
  import isArray from 'lodash/isArray'
  import isEmpty from 'lodash/isEmpty'
  import isObject from 'lodash/isObject'
  import isString from 'lodash/isString'
  import { numberFormatter } from '~/services/Helpers'

  export default {
    name: 'NumberFormat',
    functional: true,
    props: {
      number: {
        type: Number
      },
      showSign: {
        type: Boolean,
        default: true
      },
      color: {
        type: String,
        validator: val => {
          return ['black', 'gray', 'white', 'lightGray', 'green', 'red', 'bronze', 'silver', 'gold']
        }
      },
      currency: {
        type: String,
        default: null
      }
    },

    getClassObject (props, staticClass, dynamicClass) {
      let dynamicClassString = ''
      const color = props.color
      const hasCustomColor = isString(color) && !isEmpty(color)
      if (isArray(dynamicClass)) {
        dynamicClassString = dynamicClass.join(' ')
      }

      return {
        NumberFormat: true,
        'font-encodeCond': true,
        [props.number < 0 ? 'red' : 'green']: !hasCustomColor,
        [color]: hasCustomColor,
        [staticClass]: true,
        [dynamicClassString]: true,
        ...(isObject(dynamicClass) && {...dynamicClass})
      }
    },

    getSign(number) {
      return number < 0 ? '-' : '+'
    },

    getFormattedFive(number) {
      const formatted = numberFormatter(number)

      if (formatted.length < 6) return formatted

      return formatted.slice(0, 6)
    },
    getFormattedRest(number) {
      const formatted = numberFormatter(number)

      if (formatted.length < 6) return ''

      return formatted.slice(6, formatted.length)
    }
  }
