
  import Row from '@/components/layout/Row'
  import ImageNameBox from '@/components/general/elements/ImageNameBox'
  import LowerNavbarDrawerMixin from '~/configurations/mixins/LowerNavbarDrawerMixin'

  export default {
    name: 'TabletopGamesDrawer',
    components: { ImageNameBox, Row },
    mixins: [LowerNavbarDrawerMixin],
    inject: ['getLoc'],
    props: {},
    data () {
      return {
        fragment: 'tabletop_games',
        rows: []
      }
    },
    created () {
      this.rows = this.getRows(this.menuItem.children, [5, 40])
    },
    methods: {
      selectItem () {
        this.$emit('itemSelect')
      },
    }
  }
